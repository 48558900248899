#topbar {
	flex-grow: 1;
    display: flex;
	background-color: #36c;
	width: 100%;
    height: 3.2rem;
    line-height: 3.2rem;
    border-left:1px solid #000;
    overflow: hidden;
    padding-left: 1rem;
    padding-right: 1rem;

    .title {
    	font-size: 20px;
    }

    .signed-as {        
        margin-left: auto;
        color: #fff;
        a {
            color: #fff;
        }
    }
}