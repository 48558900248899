#filters {
    max-width: 700px;
    
    .filter {
        cursor: pointer;
        width: 300px;
        background-color: #313337;
        padding: 0.5rem;

        margin-right: 1rem;
        margin-bottom: 1rem;

        .title {
            font-size: 24px;
            line-height: 24px;
        }
    }
    a {
        color: #fff;
        text-decoration: none;
    }
}