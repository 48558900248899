#logviewer {
    h3 {
        font-size: 24px;
        margin-left: 0.5rem;
        padding-top: 1rem;
    }

    .filter {
        padding: 0.5rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        background-color: #36c;
        input, button {
            margin-right: 0.5rem;
        }
    }

    .spacing {
        padding: 0.5rem;
        padding-top: 0;
    }

    .userChatStats {
        background-color: #26282e;
        padding-top: 0;    
        
        h3 {
            text-transform: uppercase;
            margin-left: 0;
        }
        .user {        
            display: flex;
            gap: 1.5rem;
        }
    }



    .badge {
        margin-right: 1rem;
    }

    .table-sm td, .table-sm th {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }

    .fit-content {
        width: 1%;
        white-space: nowrap;
    }
}

#select-channels {
    background-color: #3C3C3C;
    padding: 2rem;
    width: 500px;
    margin: 10vh auto;
    font-size: 26px;
    border-radius: .25rem;


    h1 {
        text-align: center;
        margin-bottom: 1rem;
        color: #FFFFFF;
    }

    .suggest-channel {
        margin-bottom: 1rem;

        .suggest-input {
            margin: 0;
        }
    }
}