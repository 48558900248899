.chat-container {
    width: 100%;
    padding: 20px;
    background-color: #0e0e10;
    color: #e0e0e0;
    height: 100vh;
    font-size: 1rem;
}

.messages {
    height: 100%;
    overflow-y: auto;
    background-color: #18181b;
    padding: 10px;

}

.message {
    padding: 0.30rem 0;
    vertical-align: baseline;
}

.username {
    font-weight: 700;
    color: #ffa600; 
    display: inline;
}

.text {
    color: #e0e0e0;
    word-wrap: break-word;
    display: inline;
    vertical-align: baseline;
    margin-left: 0.25rem;
}

.provider {
    font-weight: bold;
    margin-right: 0.5rem;
}

.twitch {
    color: #9147ff;
}

.youtube {
    color: #ff0000;
}

.time {
    color: #8c8c8c;
    margin-right: 0.5rem;
}

.notice {
    border: 2px solid #d4af37;
    background-color: #2d2d2d;
    padding: 0.5rem;
    margin: 0.25rem;
}

.emote, .emotettv-img {
    align-items: center;
    cursor: pointer;
    display: inline-flex;
    font-style: normal;
    max-height: 1.8rem;
    justify-content: center;
    margin: -.5rem 0;
    outline: none;
    pointer-events: all;
    position: relative;
    vertical-align: middle;
}

.chat-badge {
    margin-right: 0.25rem;
    margin-bottom: 0.25rem;
}