.suggest-input {
    position: relative;
    margin-right: 0.5rem;

    input {
        margin: 0 !important;
    }

    .suggest-container {
        position: absolute;
        width: 100%;
        background-color: #43464F;
        border: 1px solid #5F5F5F;

        .suggest-item {
            cursor: pointer;
            color: #fff;
            padding: 0.5rem;
        }
    }
}