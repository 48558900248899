.form-control {    
    outline: none !important;
    box-shadow: none !important;
}

body {
    background-color: #26282e;
    color: #fff;
}

label {
    margin-bottom: 0.2rem;
}

#main-wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
}

#content-wrapper {
    flex-grow: 1;
}

#content {
    margin: 1rem;
}

.access-denied {
    color: #F72020;
    font-size: 26px;
    text-align: center;
    margin-top: 40vh
}

.box {
    border:1px solid #36c;
    border-radius: .25rem;
    .title {
        padding: 0.5rem;
        background-color: #36C;
    }
    .content {
        margin: 0.5rem;
    }
    .buttons {
        margin: 0.5rem;

        button {
            width: 100%;
            margin-bottom: 0.25rem;
        }
        button:last-child {
            margin: 0;
        }
    }
}

.table-click-row tbody tr {
    cursor: pointer;
}

.clickable {
    cursor: pointer;
}

.modal {
    display: block;

    .modal-content {
        background-color: #34363A;
    }
    .close {
        color: #fff;
    }
}

.table .td-ellipsis {
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.material-inline {
    top: .125em;
    position: relative;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

a:focus {
    outline: none;
}

textarea.form-control {
    white-space: nowrap;
}

pre {
    color: #fff;
}