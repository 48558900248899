#sidebar {
    flex-shrink: 0;
    background-color: #313337;
    min-width: 250px;
    max-width: 250px;
    height: 100vh;

    #managed-user {
        background-color: #36c;
        color: #fff;
        text-align: center;
        font-size: 20px;
        height: 3.2rem;
        line-height: 3.2rem;
        cursor: pointer;
    }

    .menuicon {
        padding-left: 1rem;
        padding-top: 6px;
        margin-right: 0.5rem;
    }

    #items {
        margin-top: 1rem;
        font-size: 18px;
        a, div {
            margin-top: 0.2rem;
            color: #F8F6F6;
            display: block;
            width: 100%;
            padding-left: 1rem;
        }

        div {
            margin-top: 1rem;
            padding-left: 1rem;
            font-size: 16px;
            color:#9F9F9F;
        }

        a.active{
            color: #007bff;
        }
    }
}