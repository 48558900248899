#front {
    width: 600px;
    margin: 3rem auto;

    .title {
        font-size: 56px;
        color: #fff;
        margin-bottom: 2rem;
        text-align: center;
    }

    .items {
        overflow: hidden;
        margin-bottom: 2rem;
        .title {
            font-size: 32px;
            color: #fff;
            margin: 0;
            text-align: left;
        }
        .item {
            text-align: center;
            float: left;
            width: 50%;
            padding: 30px;
            font-size: 26px;
            border: 1px solid #fff;
            color: #fff;
        }
        .item:last-child{
            border-left: none;    
        }
        .twitch {
            background-color: #4b367c;
        }
        .discord {
            background-color: #7289da;
        }
    }
}